import {
  Cell,
  DivT,
  Line,
  NameCell,
  Container,
  DivSumAll,
  LineCells,
  ContainerBody,
  ContainerHeader,
  NameCellWhitOut,
} from "./style";
import InputComponent from "./input";
import { useTimes } from "@/hooks/useTime";
import { useSheet } from "@/hooks/useSheet";
import { Arrow } from "../accordeonSimple/style";
import React, { useEffect, useState } from "react";
import { decimalParaHoraMinuto } from "@/utils/date";

export interface Root {
  name: string;
  actions: Action[];
  totals?: any;
  hours?: any;
  id?: any;
}

export interface Action {
  description: string;
  hours: Hora[];
  id: any;
}

export interface Hora {
  datetime: string;
  hour: string;
  id?: any;
}

interface Iprops {
  data: Root;
  hours?: any;
}

export default function Acordeon({ data }: Iprops) {
  const [open, setOpen] = useState(false);
  const [totalSumAll, setTotalSumAll] = useState<any>();

  const { currentsWeek, selectedYear, currentMonth } = useSheet();
  const { dataToPost, setActionTotalTimes, actionTotalTimes, setDataTime } = useTimes();

  const handlClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setDataTime(data);
  }, [])

  useEffect(() => {

    const updatedActionTotalTimes: { [key: string]: number } = {};

    let totalSum = 0;

    dataToPost.forEach((item: any) => {
      if (
        item.project_id === data?.id &&
        data?.actions.some((action) => action.id === item.action_type_id)
      ) {
        if (updatedActionTotalTimes[item.action_type_id]) {
          updatedActionTotalTimes[item.action_type_id] += parseFloat(item.to_sum ?? item.time);
        } else {
          updatedActionTotalTimes[item.action_type_id] = parseFloat(item.to_sum ?? item.time);
        }
        totalSum += parseFloat(item.to_sum ?? item.time);
      }
    });

    data?.actions?.forEach((action) => {
      let actionTotalTime = 0;
      action.hours.forEach((hour) => {
        actionTotalTime += parseFloat(hour.hour);
      });
      if (updatedActionTotalTimes[action.id]) {
        updatedActionTotalTimes[action.id] += actionTotalTime;
      } else {
        updatedActionTotalTimes[action.id] = actionTotalTime;
      }
      totalSum += actionTotalTime;
    });

    setTotalSumAll(totalSum);
    if (data?.actions.length === 0) {
      const totalHours = Object.values(data?.totals).reduce((sum, item: any) => sum + item?.hour, 0);
      setTotalSumAll(totalHours)
    }

    setActionTotalTimes(updatedActionTotalTimes);
  }, [dataToPost, data]);

  return (
    <Container>
      <ContainerHeader
        onClick={handlClick}
        hasActions={data?.actions?.length > 0}
      >
        {data?.actions.length == 0 && (
          <NameCellWhitOut>
            <p>{data?.name}</p>
            {data?.actions.length > 0 && <Arrow turn={open}>{">"}</Arrow>}
          </NameCellWhitOut>
        )}

        {data?.actions.length > 0 && (
          <NameCell>
            <p>{data?.name}</p>
            {data?.actions.length > 0 && <Arrow turn={open}>{">"}</Arrow>}
          </NameCell>
        )}
        <DivSumAll style={{ paddingLeft: data?.actions.length === 0 ? "0" : "59%" }}>
          <span>Total Projeto: {decimalParaHoraMinuto(totalSumAll)}</span>
        </DivSumAll>
        {data?.actions?.length === 0 && (
          <LineCells>
            {currentsWeek?.map((weeks: any, index: any) => {
              let value: any = null;
              if (data?.hours) {
                value = data?.hours.find(
                  (w: any) => w.reference_week == index + 1
                );
              }
              return (
                <Cell key={value?.id}>
                  <InputComponent
                    data={data}
                    index={index}
                    value={value}
                    weeks={weeks}
                  />
                </Cell>
              );
            })}
            <DivSumAll style={{ paddingLeft: data?.actions.length === 0 ? "0" : "59%" }}>
              <span style={{ width: "80px" }}></span>
            </DivSumAll>
          </LineCells>
        )}

      </ContainerHeader>
      <ContainerBody isOpen={open && data?.actions?.length > 0}>
        {data?.actions?.map((action, index) => {
          const actionTotalTime = actionTotalTimes[action.id] || 0;

          return (
            <Line
              key={action.id + "" + selectedYear + "" + currentMonth?.mesNumero}
            >
              <NameCell margin>
                <p>{action.description}</p>
              </NameCell>
              <LineCells>
                {currentsWeek?.map((weeks: any, index: any) => {
                  const value = action.hours.find(
                    (w: any) => Number(w.reference_week) === Number(index + 1)
                  );

                  return (
                    <Cell key={value?.id}>
                      <InputComponent
                        index={index}
                        value={value}
                        weeks={weeks}
                        data={data}
                        action={action?.id}
                      />
                    </Cell>
                  );
                })}
                <DivT>
                  <span>Total: {decimalParaHoraMinuto(actionTotalTime)}</span>
                </DivT>
              </LineCells>
            </Line>
          );
        })}
      </ContainerBody>
    </Container>
  );
}