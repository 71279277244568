import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useSheet } from '@/hooks/useSheet'
import { useTimes } from '@/hooks/useTime';
import { decimalParaHoraMinuto, horaMinutoParaDecimal } from '@/utils/date';

interface Props {
  data: any; index: any; value: any; weeks: any; action?: any;
}
export default function InputComponent({ data, index, action, value, weeks }: Props) {
  const [change, setChange] = useState(false)
  const { selectedYear, currentMonth } = useSheet()
  const [saveValue, setSaveValue] = useState(value?.hour)
  const [currentValue, setCurrentValue] = useState(value?.hour)
  const { setDataToPost, disabled, setDisabledButton, setTotals, totals } = useTimes();

  const somaHorasTotais = React.useMemo(() => {
    return totals.reduce((acc: any, total: any) => acc + total?.total || 0, 0);
  }, [totals]);

  const handleChange = (e: any) => {
    if (e.target.value != "__:__") {
      setCurrentValue(e.target.value)
      setChange(true)
    } else {
      setCurrentValue(0)
    }
    setDisabledButton(true)
  }

  const handleBlur = (e: any) => {
    try {
      if (!value) {
        const currentVal = e.target.value
        var contemLetras = /[a-zA-Z]/.test(currentVal);
        if (!contemLetras && currentVal 
          != undefined && currentVal 
          != null && currentVal.length >= 0 && 
          Number(horaMinutoParaDecimal(currentVal)) >= 0) {
            console.log("event", e.target.value, value)
            
          setDataToPost((prev: any) => {
            const existingIndex = prev.findIndex((item: any) =>
              item.project_id === data.id && item.action_type_id === action && item.reference_week === (index + 1));

            if (existingIndex !== -1) {
              const updatedItems = prev.map((item: any, idx: any) =>
                idx === existingIndex ? {
                  ...item,
                  day_reference: new Date(selectedYear, currentMonth?.mesNumero - 1, weeks[0].dia),
                  project_id: data.id,
                  time: horaMinutoParaDecimal(e.target.value) + '',
                  user_id: localStorage.getItem("USER_LOG_ID"),
                  id: value?.id,
                  update: false
                } : item
              );
              return updatedItems;
            } else {
              return [...prev, {
                day_reference: new Date(selectedYear, currentMonth?.mesNumero - 1, weeks[0].dia),
                project_id: data.id,
                action_type_id: action,
                time: horaMinutoParaDecimal(e.target.value) + '',
                user_id: localStorage.getItem("USER_LOG_ID"),
                reference_week: index + 1,
                id: value?.id,
                update: false
              }];
            }
          });

          setTotals((prev: any) => {
            return prev.map((item: any) => {
              if (item.week === index + 1) {
                // Se encontrarmos o item com a "reference week" desejada, adicionamos 10 ao campo "total"
                if (saveValue == undefined) {
                  return { ...item, total: item.total + horaMinutoParaDecimal(e.target.value) };
                } else {
                  return { ...item, total: (item.total - saveValue) + horaMinutoParaDecimal(e.target.value) };

                }
              }
              // Caso contrário, mantemos o item inalterado
              return item;
            });
          });
        }

        setCurrentValue(currentVal)
        setSaveValue(horaMinutoParaDecimal(currentVal))

      } else {
        const valueCurrent = horaMinutoParaDecimal(e.target.value)
        setSaveValue(valueCurrent)

        if (change && valueCurrent >= 0) {

          setDataToPost((prev: any) => {
            const existingIndex = prev.findIndex((item: any) =>
              item.project_id === data.id && item.action_type_id === action && item.reference_week === (index + 1));

            if (existingIndex !== -1) {
              console.log('ispect',existingIndex);
              const lastVlue = data?.hours?.filter((item: any) => item.id == value.id)[0]?.hour || 0
              const finalValueAbs = valueCurrent - lastVlue
              console.log('ispect 2lastVlue',lastVlue);
              console.log('ispect 2current',valueCurrent);
              console.log('ispect 2sub',valueCurrent - lastVlue);
              console.log('ispect 2final',finalValueAbs);
              const updatedItems = prev.map((item: any, idx: any) =>
                idx === existingIndex ? {
                  ...item,
                  day_reference: new Date(selectedYear, currentMonth?.mesNumero - 1, weeks[0].dia),
                  project_id: data.id,
                  time: valueCurrent + '',
                  user_id: localStorage.getItem("USER_LOG_ID"),
                  to_sum: finalValueAbs,
                  id: value?.id,
                  update: true
                } : item
              );
              
              console.log('ispect',updatedItems);
              return updatedItems;
            } else {
              const lastVlue = data?.hours?.filter((item: any) => item.id == value.id)[0]?.hour || 0
              const finalValueAbs = valueCurrent - lastVlue
              console.log('ispect lastVlue',lastVlue);
              console.log('ispect current',valueCurrent);
              console.log('ispect sub',valueCurrent - lastVlue);
              console.log('ispect final',finalValueAbs);
              
              return [...prev, {
                day_reference: new Date(selectedYear, currentMonth?.mesNumero - 1, weeks[0].dia),
                project_id: data.id,
                action_type_id: action,
                time:valueCurrent + '',
                user_id: localStorage.getItem("USER_LOG_ID"),
                reference_week: index + 1,
                to_sum: finalValueAbs,
                id: value?.id,
                update: true
              }];
            }
          });
        } else {
          setDataToPost((prev: any) => {
            const existingIndex = prev.findIndex((item: any) =>
              item.project_id === data.id && item.action_type_id === action && item.reference_week === (index + 1));
            console.log("existingIndex", existingIndex, e.target.value);

            if (existingIndex !== -1) {
              const updatedItems = prev.map((item: any, idx: any) =>
                idx === existingIndex ? {
                  ...item,
                  day_reference: new Date(selectedYear, currentMonth?.mesNumero - 1, weeks[0].dia),
                  project_id: data.id,
                  time: 0,
                  user_id: localStorage.getItem("USER_LOG_ID"),
                  id: value?.id,
                  update: true
                } : item
              );
              return updatedItems;
            } else {
              return [...prev];
            }
          });
        }

        setTotals((prev: any) => {
          return prev.map((item: any) => {
            if (item.week === index + 1) {
              // Se encontrarmos o item com a "reference week" desejada, adicionamos 10 ao campo "total"
              if (saveValue == undefined) {
                return { ...item, total: item.total + horaMinutoParaDecimal(e.target.value) };
              } else {
                return { ...item, total: (item.total - saveValue) + horaMinutoParaDecimal(e.target.value) };

              }
            }
            // Caso contrário, mantemos o item inalterado
            return item;
          });
        });
      }

      setChange(false)
    } catch (error) {
    }
  }

  return (

    <InputMask mask="99:99"
      maskChar="_"
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{ opacity: disabled ? '0.8' : 1 }}
      value={decimalParaHoraMinuto(currentValue) as any} />
  )
}
